import { storeToRefs } from 'pinia'
import { getMe } from '@/apis/auth'

export default defineNuxtRouteMiddleware(async (to, from) => {
  // // Online composables
  // const isOnline = useOnline()

  // // Get cookie
  // const token = useCookie('account')
  // // user store
  // const { setAccount } = useUserStore()
  // const { account } = storeToRefs(useUserStore())
  // const { execute: doGetMe, data } = getMe()

  // // if logged in -> get data
  // if (token.value && !account.value && isOnline.value) {
  //   await doGetMe().then(() => {
  //     setAccount(data.value!)
  //   })
  // }

  // return account.value && navigateTo('/feed')
  const { account } = storeToRefs(useUserStore())
  if (!account.value) {
    if (to.path !== '/')
      return navigateTo('/')
  }
  else {
    return navigateTo('/feed')
  }
})
